.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 16px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}
