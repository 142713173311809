$color-white: #ffffff;

@mixin for-extra-large-screen {
  @media screen and (min-width: 1400px) {
    @content;
  }
}

@mixin for-large-screen {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin for-medium-tab-screen {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin for-medium-screen {
  @media screen and (max-width: 991px) {
    @content;
  }
}

@mixin for-small-screen {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin for-medium-portrait-screen {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin flexDisplay($alignItems, $justifyContent) {
  display: flex;
  flex-wrap: wrap;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

@mixin toggleButton($roundedCorner) {
  font-family: $primary-toggle-font;
  padding: 0.75rem 2rem;
  border: 1px solid $primary-toggle-border-color;
  margin-left: 0.5rem;
  border-radius: $roundedCorner;
  cursor: pointer;
  .sv-string-viewer {
    color: currentColor;
  }
  &:hover {
    background: $primary-toggle-btn;
    color: $color-white !important;
  }
}

@mixin buttonPrimary($borderRadius, $textTransform) {
  background-color: $color-red;
  border: 0;
  border-radius: $borderRadius;
  font-family: $font-bold;
  letter-spacing: 0.04rem;
  @include btn-padding;
  font-size: $secondary-font-size;
  margin: 0.5rem 0;
  display: block;
  text-align: center;
  text-transform: $textTransform;
  color: $color-white;
  &:hover {
    background-color: $btn-primary-hover-color !important;
  }
  &:focus,
  &:active {
    background-color: $btn-primary-focus-color !important;
    box-shadow: none;
  }
  &:active {
    &:focus {
      box-shadow: none;
    }
  }
  &:hover,
  &:focus,
  &:active {
    color: $color-white;
  }
  &:disabled,
  &.disabled {
    background-color: rgba($color-red, 0.5);
    color: $color-white;
    cursor: not-allowed;
    pointer-events: inherit;
    opacity: 1;
    &:hover {
      background-color: rgba($color-red, 0.5) !important;
      color: $color-white;
    }
  }
}

@mixin buttonSecondary {
  @include secondary-btn;
  text-decoration: underline;
  font-family: $font-bold;
  background-color: transparent;
  border: 0;
  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
  }
}

@mixin _position($position, $top, $right, $bottom, $left) {
  position: $position;
  top: $top;
  left: $left;
  bottom: $bottom;
  right: $right;
}

@mixin formControl {
  border-radius: 0;
  height: 56px;
  border: 1px solid $form-border-primary-color;
  padding: 0 1.3rem;
  color: $body-color;
  background: $color-white;
  position: relative;
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  &:disabled {
    cursor: not-allowed;
  }
}

/* @mixin formControl {
  .form-control {
    border-radius: 0;
    height: 56px;
    border-color: $form-border-primary-color;
    padding: 0 1.3rem;
    color: $body-color;
    background: transparent;
    position: relative;
    z-index: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    &:disabled {
      cursor: not-allowed;
    }
  }
  .form-select {
    background-image: none;
  }
  &.select-form {
    position: relative;
    &:after {
      @include arrow-icon;
      color: $primary-color1;
    }
    &.form-disabled {
      &:after {
        color: $form-disable-arrow;
      }
    }
  }
} */
