@import 'poFont';
@import 'mixins';

:root {
  // --primary: $primary-color !important;
  --sjs-primary-backcolor: #002776 !important;
}

@font-face {
  font-family: Icomoon;
  src: url(./../assets/fonts/po-icomoon.woff) format('woff'), url(./../assets/fonts/po-icomoon.woff2) format('woff2'),
    url(./../assets/fonts/po-icomoon.ttf) format('truetype');
}

// @font-face {
//   font-family: GreycliffCF-medium;
//   src: url(./../assets/fonts/GreycliffCF-Medium.otf), url(./../assets/fonts/GreycliffCF-Medium.woff);
// }

// @font-face {
//   font-family: GreycliffCF-bold;
//   src: url(./../assets/fonts/GreycliffCF-Bold.otf), url(./../assets/fonts/GreycliffCF-Bold.woff);
// }

// @font-face {
//   font-family: Praxis-regular;
//   src: url('./../assets/fonts/Praxis LT Regular.eot'), url('./../assets/fonts/Praxis LT Regular.woff'),
//     url('./../assets/fonts/Praxis\ LT\ Regular.otf');
// }

// @font-face {
//   font-family: Praxis-semibold;
//   src: url('./../assets/fonts/Praxis LT Semibold.otf'), url('./../assets/fonts/Praxis LT Semibold.woff');
// }

@font-face {
  font-family: greycliffcf-regular;
  src: url('./../assets/fonts/greycliffcf-regular-webfont.woff2') format('woff2'), url('./../assets/fonts/GreycliffCF-Regular.otf'),
    url('./../assets/fonts/greycliffcf-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Colors
$body-color: #666666;
$footer-bg: #0a1949;
$color-red: #b10f26;
$color-gold: #988642;
$primary-color: #002776;
$secondary-color: #0076ce;
$secondary-color1: #f2f0e8;
$btn_hover: #b0263a;
$btn_active: #8d0c1e;
$_tile_brd_clr: rgba(0, 39, 118, 0.35);
$_form_brd_clr: #a6b4cf;
$_form_color: #666666;
$btn_dis_color: #ccc;
$selection_btn_active: #e3e2e8;
$selection_btn_hover: #bcbdcb;
$selection_active_brd: $selection_btn_active;
$selection_hover_color: $primary-color;
$link-color: $primary-color;
$btn-primary-hover-color: #b0263a;
$btn-primary-focus-color: #8d0c1e;
$form-border-primary-color: #a6b4cf;
$secondary-btn-border-color: $secondary-color;
$secondary-btn-color: $secondary-color;
$primary-toggle-btn: $secondary-color;
$primary-toggle-border-color: $secondary-color;
$primary-toggle-btn-color: $secondary-color;

// Font Family
$primary-font: GreycliffCF-light;
$secondary-font: GreycliffCF-medium;
$font-bold: GreycliffCF-bold;
$body-font: Praxis-regular;
$body-semibold: Praxis-semibold;
$primary-icon-font: Icomoon;
$primary-toggle-font: $font-bold;

$primary-font-size: 1rem;
$secondary-font-size: 1.125rem;

$borderRadius: 20px;
$textTransform: inherit;
$roundedCorner: 1rem;

@mixin btn-padding {
  padding: 1.2rem 2.75rem;
  @include for-small-screen {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@mixin secondary-btn {
  color: $secondary-color !important;
  &:hover,
  &:focus,
  &:active {
    color: $secondary-color !important;
  }
}

.btn,
.page-title-section__back-button,
.book-slot .slot-card__list--btn,
.notes-count__btn,
.child-name-sessions__details--btn {
  border-radius: 10px;
  text-transform: uppercase;
}

// Login Page
.app-login {
  .app-login-card {
    &__logo {
      max-width: 275px;
      @media screen and (max-width: 520px) {
        max-width: 200px;
      }
    }
    &__form {
      &--btn {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
      }
    }
  }
}
// End Login Page

// Page Title Section
.page-title-section {
  &__back-button {
    padding: 8px 30px 10px !important;
  }
}
// End Page Title Section

.btn-submit {
  padding: 9px 30px 11px !important;
}

// Date List
.date-list {
  &__btn {
    background: $selection_btn_active;
    border: 1px solid $selection_btn_active;
  }
}
// End Date List

.home_advance_report .home-report-card__item--btn-report .btn-primary {
  text-transform: uppercase;
}

.ym_menu .dropdown .dropdown-toggle {
  text-transform: inherit !important;
}

/* Preview Modal Popup */
.obnq-preview-modal {
  .modal-header {
    .obnq-modal-close {
      &:after {
        content: '\e906';
      }
    }
  }
}
/* End Preview Modal Popup */
