.wrapper {
  // min-height: 100px;
  position: relative;
  pointer-events: none;
  height: 100vh;
}

.loaderContainer {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  content: '';
  cursor: wait;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -20px;
  z-index: 10;
}
