@import 'po';
/* @import 'cunard'; */

$color-white: #fff;

@mixin flexCenter($alignItems, $justifyContent) {
  display: flex;
  flex-wrap: wrap;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

@mixin content-border {
  border: 1px solid $_tile_brd_clr;
  padding: 1rem;
  margin-bottom: 1rem;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
}

@mixin btn_font_family {
  font-family: $font-bold;
}

@mixin btn_size {
  padding: 9px 20px 10px;
  font-size: 0.9rem;
}

@mixin btn_size_secondary {
  padding: 8px 30px 7px;
  font-size: 0.8rem;
  border-radius: 10px;
}

@mixin btn_primary {
  background: $color-red;
  border-color: $color-red;
  @include btn_hover_focus;
  color: $color-white;
  display: inline-block;
}

@mixin btn_secondary {
  background: $secondary-color;
  border-color: $secondary-color;
  @include btn_size_secondary;
  @include btn_font_family;
  &:focus,
  &:hover {
    box-shadow: none;
    background: transparent;
    color: $secondary-color;
  }
}

@mixin btn_teritary {
  background: $color-white;
  border-color: $secondary-color;
  color: $secondary-color;
  @include btn_size_secondary;
  @include btn_font_family;
  &:focus,
  &:hover {
    box-shadow: none;
    background: transparent;
    color: $secondary-color;
  }
}

@mixin btn_hover_focus {
  @include btn_font_family;
  @include btn_size;
  &:hover {
    background-color: $btn_hover !important;
    border-color: $btn_hover !important;
  }
  &:focus,
  &:active {
    background-color: $btn_active !important;
    border-color: $btn_active !important;
  }
  &:focus,
  &:hover {
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent !important;
    border-color: $btn_dis_color !important;
    color: $btn_dis_color !important;
    cursor: not-allowed;
    pointer-events: inherit !important;
    opacity: 1 !important;
    &:hover {
      background: transparent !important;
      border-color: $btn_dis_color !important;
    }
  }
}

@mixin form_control {
  height: 56px;
  padding: 0px 15px;
  border-radius: 0;
  border-color: $_form_brd_clr;
  color: $_form_color;
  font-size: 0.9rem;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  }
}
